<template>
  <nav>
    <ul class="menu">
      <li class="menu-item"><router-link to="/">Home</router-link></li>
      <li class="menu-item"><router-link to="/tokens">Tokens</router-link></li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'NavigationBarComponent'
}
</script>

<style scoped>
  .menu {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  .menu-item a {
    display: block;
    width: 100%;
    padding: 10px;

    color: #fefefe;
    font-size: 20px;
    text-decoration: none;
  }

  .menu-item a:hover,
  .menu-item a.router-link-active {
    background-color: #0f0e0e;
  }
</style>
