<template>
  <div class="about">
    <h2>ERC 20 - Tokens</h2>
    <TokenAddressComponent @submit="getAddress"/>
    <TokenDataComponent :address="address"/>
  </div>
</template>

<script>
import TokenAddressComponent from '@/components/TokenAddressComponent.vue'
import TokenDataComponent from '@/components/TokenDataComponent.vue'

export default {
  name: 'TokenView',
  components: {
    TokenAddressComponent,
    TokenDataComponent
  },
  data() {
    return {
      address: null
    }
  },
  methods: {
    getAddress(address) {
      this.address = address;
    }
  }
}
</script>

<style scoped></style>
