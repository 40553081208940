<template>
  <div class="token-address-form">
    <form @submit.prevent="submit">
      <label for="token-address">Token Address:</label>
      <input type="text" id="token-address" name="token-address" pattern="^0[xX][0-9a-fA-F]{40}" v-model="address">
      <input type="submit" value="Find">
    </form>
  </div>
</template>

<script>
export default {
  name: 'TokenAddressComponent',
  data() {
    return {
      address: null
    }
  },
  emits: ['submit'],
  methods: {
    submit() {
      this.$emit('submit', this.address)
    }
  }
}
</script>


<style scoped>
.token-address-form {
  padding: 20px 0;
}

label {
  font-size: 25px;
}

input {
  height: 30px;
  margin-left: 10px;
}
input[type=text] {
  width: 360px;
  padding: 5px 10px;
}

input[type=submit]{
  font-size: 16px;
  margin-left: 0;
  padding: 0 30px;
}
</style>
