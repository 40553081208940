<template>
  <header>
    <div class="logo"><img alt="1000x logo" class="logo" src="../assets/img/logo_small.png"></div>
    <div class="titel"><h1>{{ titel }}</h1></div>
  </header>
</template>

<script>
export default {
  name: 'HeaderBarComponent',
  props: {
    titel: String
  }
}
</script>


<style scoped>
  header::after {
    content: "";
    display: table;
    clear: both;
  }

  .logo {
    height: 100%;
    float: left;
  }

  .titel {
    height: 100%;
    padding: 0 0 0 30px;
    margin: 0;
    float: left;
  }
</style>
