<template>
  <div class="home">
    <h2>Home</h2>
    <div class="girt-container-3">
      <BlockHeightComponent/>
      <BlockHeightComponent/>
      <BlockHeightComponent/>
      <BlockHeightComponent/>
    </div>
  </div>
</template>

<style scoped></style>

<script>
import BlockHeightComponent from '@/components/BlockHeightComponent.vue'

export default {
  name: 'HomeView',
  components: {
    BlockHeightComponent
  }
}
</script>
