import { createStore } from 'vuex';
import { Network } from '@/utils/Network.js';

const network = new Network('https://node.1000x.ch');

export default createStore({
  state: {
    blockNumber: 0,
    tokenAddress: "UNKNOWN",
    token: null,
    tokenName: "UNKNOWN",
    tokenSymbol: "UNKNOWN",
    tokenDecimals: "UNKNOWN",
    tokenTotalSupply: "UNKNOWN",
    tokenCreator: "UNKNOWN",
  },
  getters: {
  },
  mutations: {
    async loadBlockNumber (state) {
      state.blockNumber = await network.getBlockNumber();
    },
    async loadToken (state) {
      state.token = network.getToken(state.tokenAddress);
      state.tokenAddress = await state.token.getAddress();
      state.tokenName = await state.token.getName();
      state.tokenSymbol = await state.token.getSymbol();
      state.tokenDecimals = await state.token.getDecimals();
      state.tokenTotalSupply = await state.token.getTotalSupply();
    }
  },
  actions: {
  },
  modules: {
  }
})
