import { Web3 } from 'web3';

const ierc20 = require('@/assets/abi/IERC20.json')

class Token {
	constructor(web3, address) {
		this.utils = web3.utils
		this.contract = new web3.eth.Contract(ierc20, address)
	}

	getAddress() {
		return this.contract.options.address;
	}

	getSymbol() {
		return this.contract.methods.symbol().call();
	}

	getName() {
		return this.contract.methods.name().call();
	}

	getDecimals() {
		return this.contract.methods.decimals().call();
	}

	async getTotalSupply() {
		console.log(this.contract)
		const decimals = await this.getDecimals();
		const totalSupply = await this.contract.methods.totalSupply().call();
		return totalSupply / 10n ** decimals;
	}
}

class Network {
	constructor(url) {
		this.web3 = new Web3(url);
	}

	getBlockNumber() {
		return this.web3.eth.getBlockNumber();
	}

	getToken(address) {
		return new Token(this.web3, address);
	}
}

export { Network };