<template>
  <div class="block-height">
    <h3>Block Height</h3>
    <img class="chain-icon" src = "@/assets/img/chain2.svg" alt="Chain Icon"/>
    <span class="block-height-number">{{ blockNumber }}</span>
  </div>
</template>

<script>
export default {
  name: 'BlockHeightComponent',
  computed: {
    blockNumber () {
      return this.$store.state.blockNumber
    }
  },
  mounted() {
    this.$store.commit('loadBlockNumber');
  }
}
</script>


<style scoped>
  .block-height {
    border: 3px solid #000;
    background-color: #151414;
    padding: 20px 0;
  }

  img {
    display: block;
    width: 80px;
    margin: 0 auto 10px auto;
  }

  h3 {
    text-align: center;
  }

  .block-height-number {
    display: inline-block;
    width: 100%;
    text-align: center;
    font-size: 30px;
  }
</style>
