<template>
  <HeaderBarComponent titel="1000x Tools"/>
  <NavigationBarComponent/>
  <main>
    <router-view/>
  </main>
</template>

<script>
import NavigationBarComponent from '@/components/NavigationBarComponent.vue'
import HeaderBarComponent from '@/components/HeaderBarComponent.vue'

export default {
  name: 'App',
  components: {
    HeaderBarComponent,
    NavigationBarComponent
  }
}
</script>

<style scoped>

</style>
