<template>
  <div class="token-data">
    <table>
      <tr>
        <th>Name</th>
        <th>Value</th>
      </tr>
      <tr>
        <td>Address</td>
        <td>{{ tokenAddress }}</td>
      </tr>
      <tr>
        <td>Name</td>
        <td>{{ tokenName }}</td>
      </tr>
      <tr>
        <td>Symbol</td>
        <td>{{ tokenSymbol }}</td>
      </tr>
      <tr>
        <td>Decimals</td>
        <td>{{ tokenDecimals }}</td>
      </tr>
      <tr>
        <td>TotalSupply</td>
        <td>{{ tokenTotalSupply }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: 'TokenDataComponent',
  props: {
    address: String
  },
  watch: {
    address: function(newVal) {
      this.$store.state.tokenAddress = newVal;
      this.$store.commit('loadToken');
    }
  },
  computed: {
    tokenAddress () {
      return this.$store.state.tokenAddress;
    },
    tokenName () {
      return this.$store.state.tokenName;
    },
    tokenSymbol () {
      return this.$store.state.tokenSymbol;
    },
    tokenDecimals () {
      return this.$store.state.tokenDecimals;
    },
    tokenTotalSupply () {
      return this.$store.state.tokenTotalSupply;
    }
  },
  methods: {
    copyTokenAddress() {
      console.log(this.tokenAddress);
    }
  }
}
</script>


<style scoped>
table {
  width: 100%;
  border: none;
  border-collapse:collapse;
}

tr:nth-child(odd) {
  background-color: #333232;
}

th {
  font-size: 20px;
  text-align: left;
}

th:first-child{ width: 200px; }


td {
  vertical-align: middle;
}

button {
  height: 38px;
  border: 1px solid #000;
  border-radius: 0;
  padding: 5px 10px;
  background-color: #151414;
}

button:hover {
  background-color: #3c3939;
}
</style>
